import './assets/css/App.css';
import './assets/css/font.css';
import { useState, useEffect } from 'react';
import starts from './assets/icon/start.png';
import LazyLoad from 'react-lazy-load';
import starts_1 from './assets/icon/b.png';
import starts_2 from './assets/icon/b2.png';
import starts_3 from './assets/icon/b3.png';
import starts_4 from './assets/icon/b4.png';
import Menu from './components/Menu';
import UserHeader from './components/UserHeader';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Withdrawal from './pages/withdrawal';
import Topup from './pages/topup';
import Friends from './pages/friends';
const tg = window.Telegram.WebApp;
function App() {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(false);
  const [showS, setShowS] = useState(false);
  const initData = window.Telegram.WebApp.initData || "query_id=AAE5OFkwAwAAADk4WTC110Mb&user=%7B%22id%22%3A7253604409%2C%22first_name%22%3A%22W%22%2C%22last_name%22%3A%22%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1726223183&hash=f9b040aabdade7b2c5044b88cb6e02b5e6a77092afb6796f1326e8ba762844df";
  if (!initData) {
    console.error("initData is not set");
  }
  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(initData),
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    };

    fetch('https://api.miningpoolcash.com/auth', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        localStorage.setItem('token', data.result);
        window.token = localStorage.getItem('token');
        if (!window.token) {
          console.error("Token is not set");
          return;
        }
        if (data.create == true) {
          setStep(1);
        }
        // Set the API endpoint URL
        const apiUrl = 'https://api.miningpoolcash.com/api/users/get';
        
        // Set the request headers
        const headers = {
          'X-Token': `Bearer ${window.token}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        };
        
        // Make the GET request to the API endpoint
        fetch(apiUrl, {
          method: 'GET',
          headers: headers,
          cache: 'no-cache'
        })
        .then(response => response.json())
        .then(data => {console.log(data);window.user = null;window.user = data.result; setLoading(false);})
        .catch(error => console.error(error));



        
      })
      .catch(error => console.error(error));
    });
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      setShowS(true);
    }, 350); // задержка в 500мс
  }, [step]); // add step as a dependency
  
  const handleContinueClick = () => {
    setShow(false);
    
    setTimeout(() => {
      setStep(step + 1);
      setShow(true); // restart the animation
    }, 350 + step); // задержка в 500мс
  };
  if (loading) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`} >
              </div>
            </div>
          </header>
        </div>
      </Router>
    ); // Display loading indicator
  }
  if (step === 1) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`} >
                <LazyLoad offset={100} throttle={200}>
                  <img src={starts} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Привет, {window.user.name}!</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Мы рады видеть нового игрока в мире майнинга!</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  } else if (step === 2) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
                <LazyLoad offset={100} throttle={200}>
                  <img src={starts_1} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Зарабатывай!</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">В нашем мире ты можешь использовать майнинг для заработка денег!</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  } else if (step === 3) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
              <LazyLoad offset={100} throttle={200}>
                  <img src={starts_2} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Прокачивай видеокарту</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Улучшай свою карту, прибыль будет становится больше. А карманы жирнее!</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  }
  else if (step === 4) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
              <LazyLoad offset={100} throttle={200}>
                  <img src={starts_3} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad> 
                <div class="new_user_item_title">Выводи</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Все, что ты заработал, ты можешь вывести удобным для себя способом.</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  }
  
  else if (step === 5) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
                <LazyLoad offset={100} throttle={200}>
                  <img src={starts_4} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Ну что, начнем?</div>
                <div class="new_user_item_key" onClick={handleContinueClick}>Погнали!</div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  }
   else {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <UserHeader />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/withdrawal" element={<Withdrawal/>} />
              <Route path="/topup" element={<Topup />} />
              <Route path="/friends" element={<Friends />} />
            </Routes>
            <Menu />
          </header>
        </div>
      </Router>
    );
  }
}

export default App;