import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo_money from '../assets/icon/moneys.png';
import dop from '../assets/icon/dop.png';
import farm from '../assets/icon/farm.gif';
import white from '../assets/icon/White.png';
import setting_server from '../assets/icon/setting_server.png';
import err from '../assets/icon/err.png';
import start_mine from '../assets/icon/start_mine.png';
function Home() {
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/topup');
      };

    // Состояния
    const [setShowConfirmPopup] = useState(false);
    const [farmStartup, setFarmStartup] = useState(null); // new state variable
    const [fadeIn, setFadeIn] = useState(false);
    const [elements, setElements] = useState(window.user.card);
    const [remainingTime, setRemainingTime] = useState({}); // object to store remaining time for each element
    const [farmedCoins, setFarmedCoins] = useState({}); // object to store farmed coins for each element

    useEffect(() => {
        const updateRemainingTimeAndFarmedCoins = () => {
            setRemainingTime((prevRemainingTime) => {
                return elements.reduce((acc, element) => {
                    if (Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0) {
                        const remainingSeconds = Math.max(0, element.endTime - Math.floor(Date.now() / 1000));
                        acc[element.id] = remainingSeconds;
                    }
                    return acc;
                }, {});
            });

            setFarmedCoins((prevFarmedCoins) => {
                return elements.reduce((acc, element) => {
                    if (Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0) {
                        const endTime = element.endTime;
                        const currentTime = Date.now() / 1000;
                        const timeDiff = endTime - currentTime;
                        const hours = Math.floor(timeDiff / 3600);
                        const minutes = Math.floor((timeDiff % 3600) / 60);
                        const seconds = Math.floor(timeDiff % 60);

                        const farmedCoins = (hours * element.count) + (minutes * element.count / 60) + (seconds * element.count / 3600);
                        acc[element.id] = element.count - farmedCoins;
                    }
                    return acc;
                }, {});
            });
        };

        const intervalId = setInterval(updateRemainingTimeAndFarmedCoins, 1000); // update every 1 second

        return () => {
            clearInterval(intervalId);
        };
    }, [elements]);

    const handleFarmClick = (id) => {
        const element = elements.find((element) => element.id === id);
        if (!element) return; // проверяем, существует ли элемент

        if (Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0) {
            setShowConfirmPopup(true); // Показываем попап при попытке остановить фарм
        } else {
            startFarm(id);
        }
    };


    const startFarm = (id) => {
        setFarmStartup('connecting');
        setTimeout(() => {
            setFarmStartup('mining');
            setTimeout(() => {
                setFadeIn(false);
                setTimeout(() => {
                    setFarmStartup(null);
                    updateElement(id);
                }, 500);
            }, Math.floor(Math.random() * 1000) + 250);
        }, Math.floor(Math.random() * 1000) + 400);
    };

    useEffect(() => {
        if (farmStartup === 'connecting') {
            setFadeIn(true);
        } else if (farmStartup === 'mining') {
            setFadeIn(true);
        }
    }, [farmStartup]);
    const updateElement = (id) => {
        const apiUrl = 'https://api.miningpoolcash.com/api/users/farm_start/'+id;
        const headers = {
            'X-Token': `Bearer ${window.token}`,
            'Content-Type': 'application/json'
        };
        fetch(apiUrl, {
            method: 'PATCH',
            headers: headers,
        })
        .then(response => response.json())
        .then(data => {
            window.user = data;
            return setElements(window.user.card);

        })
        .catch(error => console.error(error));
    };
    return (
        <>
            {farmStartup && (
                <div
                    className={`farm-startup-overlay ${farmStartup === 'connecting' ? 'connecting' : 'mining'}`}
                    style={{
                    opacity: fadeIn ? 1 : 0,
                    transition: 'opacity 0.2s ease-in-out',
                    }}
                >
                {farmStartup === 'connecting' ? (
                    <div className='farm-startup-overlay-connecting'>
                    <img src={setting_server} className="farm-startup-overlay-connecting-img" alt="logo" />
                    Устанавливаем связь с серверам
                    </div>
                ) : (
                    <div className='farm-startup-overlay-connecting'>
                    <img src={start_mine} className="farm-startup-overlay-connecting-img" alt="logo" />
                    Запускаем майнинг
                    </div>
                )}
                </div>
            )}
                {elements.map((element) => (
                <div key={element.id} className="item_home_element top">
                    <div
                        className={`item_home_pr  ${Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0 ? 'active-farm' : ''}`}
                        >
                        {Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0 ? (
                            <>
                            <img src={farm} className="item_home_pr_money-farm" alt="logo" />
                            <img src={white} className="item_home_pr_money-farm item_home_pr_money-farm-blur" alt="logo" />
                            <div class="item_home_pr_item_ch">
                                Фарм в блокчейни запущен. До завершения: {Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) || 'Не запущен'} секунд
                            </div>
                            </>
                        ) : (
                            <>
                            <img src={err} className="item_home_pr_money" alt="logo" />
                            <img src={err} className="item_home_pr_money-farm item_home_pr_money-farm-blur" alt="logo" />
                            <div className="item_home_pr_item_ch">
                                Не забудьте запустить видеокарту!
                            </div>
                            </>
                        )}
                    </div>
                <div className="item_home_element top">
                    <div className="item_up_conn">
                    <div className="item_up_con">
                        <div className="item_up_element_con">
                        <div className="item_element_title_flex">
                            <div
                            className={`status_farm ${
                                Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0 ? 'active-status-form' : ''
                            }`}
                            ></div>
                            <div className="item_element_title">{element.name}</div>
                        </div>
                        <div className="item_up_element">
                            <img
                            src={require(`../assets/icon/${element.photo}`)}
                            className={`item_up_element-icon ${
                                Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0 ? 'active-farm-animation' : ''
                            }`}
                            alt="logo"
                            />
                            <div className="item_up_element-name"></div>
                            <div className="item_up_element-con-price">
                            <div className="item_up_element-con-element">
                                <img src={logo_money} className="item_up_element-price-l" alt="logo" />
                                <div className="item_up_element-price">
                                    {Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0 ? (
                                        <>
                                        {(() => {
                                            const currentTime = Date.now() / 1000;
                                            const timeDiff = element.endTime - currentTime;
                                            const hours = Math.floor(timeDiff / 3600);
                                            const minutes = Math.floor((timeDiff % 3600) / 60);
                                            const seconds = Math.floor(timeDiff % 60);
                                            var farmedCoins = (hours * element.count) + (minutes * element.count / 60) + (seconds * element.count / 3600);
                                            farmedCoins = element.count - farmedCoins;

                                            window.user.coin -= parseFloat(window.user.coins_f[element.id]).toFixed(4);
                                            window.user.coin = (parseFloat(window.user.coin) + parseFloat(farmedCoins.toFixed(4))).toFixed(4);
                                            window.user.coins_f[element.id] = parseFloat(farmedCoins.toFixed(4));
                                            return `Нафармлено: ${farmedCoins.toFixed(4)} / ${element.count} RUB`;
                                        })()}
                                        </>
                                    ) : (
                                        `Прибыль в час: ${element.count} RUB`
                                    )}
                                </div>
                            </div>
                            </div>
                            {Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) < 1 && (
                            <div
                                className="item_up_element-key-f"
                                onClick={() => handleFarmClick(element.id)}
                            >
                                Запустить фарм
                            </div>
                            )}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            ))}
            <div className = "item_up_arenda">
                <img src={dop} className="item_up_arenda-icon" alt="logo" />
                <img src={dop} className="item_up_arenda-icon item_up_arenda-icon-blur" alt="logo" />
                <div className='item_friend-icon-text-con'>
                    <div className='item_friend_pod-text'>
                    Вы можете иметь несколько видеокарт!
                    <div className='item_up_element-key' onClick={handleUpgradeClick}>Арендовать</div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Home;
