import React from 'react';
import logo_money from '../assets/icon/money.png';
import wallet from '../assets/icon/wallet.png';
function withdrawal() {
    return (
        <>
        <div className='title-item'>Вывод</div>
            <div className="item_home_element top">
                <div className = "item_withdrawal">
                    <img src={logo_money} className="item_topup-icon" alt="logo" />
                    <div className='item_friend-icon-text-con'>
                        <div className='item_friend-icon-text'>
                            Напишите желаемую сумму вывода:
                        </div>
                    </div>
                    <div className='item_topup-input-con'>
                    <input 
                        className='item_topup-input'
                        type="text"
                        value="100"
                        onChange=""
                        placeholder="Введите текст"
                    />
                    </div>
                    <div className='item_friend_count'>
                    После этого выберите способ:
                    </div>
                </div>

                <div className='item_type-m-con'>
                    <div className='item_type-m'>
                        <img src={wallet} className="item_type-m-icon" alt="logo" />
                        <div className='item_type-m-name'>Wallet</div>
                        <div className='item_type-m-key'>Пополнить</div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default withdrawal;