import React from 'react';
import logo_money from '../assets/icon/moneys.png';
import wallet from '../assets/icon/wallet.png';
import bitcoin from '../assets/icon/mine.png';
import logo_p from '../assets/icon/arenda.png';
function topup() {
    return (
        <>
            <div className="item_home_element top">
                <div className = "item_topup_pod">
                    <img src={logo_p} className="item_friend_pod-icon" alt="logo" />
                    <img src={logo_p} className="item_friend_pod-icon item_friend_pod-icon-blur" alt="logo" />
                    <div className='item_friend-icon-text-con'>
                        <div className='item_friend_pod-text'>
                        Наши видеокарты расположены в дата-центре, обеспечивая максимальную производительность, надежность и безопасность
                        </div>
                    </div>
                </div>
                <div className = "item_topup_con">
                    <div className='item_topup_element'>
                    <img 
                            src={bitcoin} 
                            className="item_up_element-icon-arend"
                            alt="logo" 
                        />
                        <div class="item_up_element-name">GeForce GTX 1080 Ti</div>
                        <div className='item_up_element-con-price'>
                            <div className='item_up_element-con-element'>
                                <img src={logo_money} className="item_up_element-price-l" alt="logo" />
                                <div className='item_up_element-price'>
                                   Прибыль в час: 10 RUB
                                </div>
                            </div>
                        </div>
                        <div class="item_up_element-name-arenda-price">Стоимость аренды: 100 RUB</div>
                        <div class="item_up_element-key-arenda">Взять в аренду</div>
                    </div>
                    <div className='item_topup_element'>
                        <img 
                            src={bitcoin} 
                            className="item_up_element-icon-arend"
                            alt="logo" 
                        />
                        <div class="item_up_element-name">GeForce GTX 1080 Ti</div>
                        <div className='item_up_element-con-price'>
                            <div className='item_up_element-con-element'>
                                <img src={logo_money} className="item_up_element-price-l" alt="logo" />
                                <div className='item_up_element-price'>
                                   Прибыль в час: 10 RUB
                                </div>
                            </div>
                        </div>
                        <div class="item_up_element-name-arenda-price">Стоимость аренды: 100 RUB</div>
                        <div class="item_up_element-key-arenda">Взять в аренду</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default topup;