import React, { useState, useEffect } from 'react';
import logo from '../avatar.jpg';
import logo_money from '../assets/icon/moneys.png';
import '../assets/css/UserHeader.css';

const UserHeader = () => {
  const [balance, setBalance] = useState(window.user.coin); // Initialize balance with current value

  useEffect(() => {
    const updateBalance = () => {
      setBalance(window.user.coin);
    };

    updateBalance(); // Update balance initially

    const intervalId = setInterval(updateBalance, 500); // Update balance every 1 second

    return () => {
      clearInterval(intervalId); // Clean up interval on component unmount
    };
  }, []);
  const [elements, setElements] = useState(window.user.card);
  const [farmedCoins, setFarmedCoins] = useState({}); // object to store farmed coins for each element

  useEffect(() => {
      const updateRemainingTimeAndFarmedCoins = () => {
          setFarmedCoins((prevFarmedCoins) => {
            setElements(window.user.card);
              return elements.reduce((acc, element) => {
                  if (Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0) {
                      const endTime = element.endTime;
                      const currentTime = Date.now() / 1000;
                      const timeDiff = endTime - currentTime;
                      const hours = Math.floor(timeDiff / 3600);
                      const minutes = Math.floor((timeDiff % 3600) / 60);
                      const seconds = Math.floor(timeDiff % 60);

                      const farmedCoins = (hours * element.count) + (minutes * element.count / 60) + (seconds * element.count / 3600);
                      acc[element.id] = element.count - farmedCoins;
                      window.user.coin -= parseFloat(window.user.coins_f[element.id]);
                      window.user.coin = (parseFloat(window.user.coin) + parseFloat(acc[element.id].toFixed(4))).toFixed(4);
                      window.user.coins_f[element.id] = parseFloat(acc[element.id].toFixed(4));
                  }
                  return acc;
              }, {});
          });
      };

      const intervalId = setInterval(updateRemainingTimeAndFarmedCoins, 1000); // update every 1 second

      return () => {
          clearInterval(intervalId);
      };
  }, [elements]);

  return (
    <div className="userinfo">
      <img src={logo} className="user_logo_item" alt="logo" />
      <img src={logo} className="user_logo_item user_logo_item-blur" alt="logo" />
      <div className="item_user_date">
        {window.user && (
          <div className="item_user_name">{window.user.name}</div>
        )}
      </div>
      <div className="item_user_coin">
        <img src={logo_money} className="user_logo_money" />
        <img src={logo_money} className="user_logo_money user_logo_money-blur" />
        {window.user && (
            <div className="money">{balance} RUB</div>
        )}
      </div>
    </div>
  );
};

export default UserHeader;