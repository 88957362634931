import React, { useState,useEffect } from 'react';
import logo from '../assets/icon/f-im.png';
import logo_money from '../assets/icon/moneys.png';
import logo_p from '../assets/icon/p.png';
import bitcoin from '../assets/icon/mine.png';
import share from '../assets/icon/share.svg';
function Friends() {
    const [copied, setCopied] = useState(false);
    const [inputValue, setInputValue] = useState("https://t.me/miningpoolcashbot?start="+window.user.ID);
    const [progressWidth, setProgressWidth] = useState(0);
    const handleCopy = () => {
        navigator.clipboard.writeText(inputValue);
        setCopied(true);
        setTimeout(() => {
        setCopied(false);
        }, 2000);
    };

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };
    
    useEffect(() => {
        if (window.user.f_count === 0) {
          setProgressWidth(0);
        } else if (window.user.f_count >= 1 && window.user.f_count <= 4) {
          setProgressWidth(window.user.f_count * 20);
        } else {
          setProgressWidth(100);
        }
      }, [window.user.f_count]);
    return (
        <>
            <div className="item_home_element top">
                <div className = "item_friend_pod top">
                    <img src={logo_p} className="item_friend_pod-icon" alt="logo" />
                    <img src={logo_p} className="item_friend_pod-icon item_friend_pod-icon-blur" alt="logo" />
                    <div className='item_friend-icon-text-con'>
                        <div className='item_friend_pod-text'>
                            Приводите друзей и получайте бесплатно видеокарты для фарма
                        </div>
                    </div>
                </div>
                <div className = "item_friend">
                    <a href={`https://telegram.me/share/url?url=${inputValue}`}><img src={share} className="share" alt="logo" /></a>
                    <img src={logo} className="item_friend-icon" alt="logo" />
                    <div className='item_friend-icon-text-con'>
                        <div className='item_friend-icon-text'>
                            Используйте данную ссылку для приглашения:
                        </div>
                    </div>
                    <input
                        className='item_friend-input'
                        type="text"
                        value={inputValue}
                        disabled={true}

                    />
                    <div className="item_friend_key" onClick={handleCopy}>
                        {copied ? "Успешно скопировано" : "Скопировать"}
                    </div>
                </div>

                <div className='item_topup_element top margin-b'>
                        <img 
                            src={bitcoin} 
                            className="item_up_element-icon-arend"
                            alt="logo" 
                        />
                        <div class="item_up_element-name">GeForce GTX 1080 Ti</div>
                        <div className='item_up_element-con-price'>
                            <div className='item_up_element-con-element'>
                                <img src={logo_money} className="item_up_element-price-l" alt="logo" />
                                <div className='item_up_element-price'>
                                   Прибыль в час: 1 RUB
                                </div>
                            </div>
                        </div>
                        <div class="progress-container top">
                            <style>
                                {`:root {
                                --progress-width: ${progressWidth}%;
                                }`}
                            </style>
                            <progress value={(window.user.f_count === 0) ? 0 : (window.user.f_count >= 1 && window.user.f_count <= 4) ? window.user.f_count * 20 : 100} max="100"></progress>
                        </div>
                        <div className='item_friend_count'>
                            Осталось пригласить: {window.user.f_count > 5 ? 0 : 5 - window.user.f_count}
                        </div>
                    </div>

            </div>
        </>
    );
}

export default Friends;